<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.91667 12.8312H11.0833C11.3928 12.8312 11.6895 12.7083 11.9083 12.4895C12.1271 12.2707 12.25 11.9739 12.25 11.6645V6.41452C12.2504 6.33775 12.2357 6.26165 12.2067 6.19058C12.1777 6.11951 12.1349 6.05486 12.0808 6.00036L7.41417 1.33369C7.30487 1.22505 7.15702 1.16406 7.00292 1.16406C6.84881 1.16406 6.70096 1.22505 6.59167 1.33369L1.925 6.00036C1.86988 6.05438 1.82603 6.11881 1.79599 6.1899C1.76595 6.261 1.75032 6.33735 1.75 6.41452V11.6645C1.75 11.9739 1.87292 12.2707 2.09171 12.4895C2.3105 12.7083 2.60725 12.8312 2.91667 12.8312ZM5.83333 11.6645V8.74786H8.16667V11.6645H5.83333ZM2.91667 6.65369L7 2.57036L11.0833 6.65369V11.6645H9.33333V8.74786C9.33333 8.43844 9.21042 8.14169 8.99162 7.9229C8.77283 7.70411 8.47609 7.58119 8.16667 7.58119H5.83333C5.52391 7.58119 5.22717 7.70411 5.00838 7.9229C4.78958 8.14169 4.66667 8.43844 4.66667 8.74786V11.6645H2.91667V6.65369Z"
      fill="url(#paint0_linear_1177_878)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1177_878"
        x1="1.75"
        y1="1.16406"
        x2="13.0092"
        y2="1.94961"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A2165B" />
        <stop offset="1" stop-color="#5A004B" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "HomeIcon",
};
</script>