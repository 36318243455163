<template>
  <section class="tender-details main-wrapper">
    <div class="main-content">
      <header-vue />
      <bread-crumbs :breadcrumbItems="breadcrumbItems" />

      <div class="container text-center-mobile-only pb-1 pl-5 pr-5 pt-5 mb-4">
        <div
          class="
            is-flex is-align-items-center is-justify-content-space-between
            mb-4
          "
        >
          <h2
            class="
              main-title
              is-size-3-5-mobile
              has-text-left has-text-centered-mobile
              mr-4
            "
          >
            Контакты
          </h2>
        </div>
      </div>
      <div class="container pb-1 pl-5 pr-5 mb-4">
        <div class="contacts-container p-6 mt-5">
          <div class="contacts-nav">
            <a :class="{ active: currentCity == 1 }" @click="currentCity = 1">
              <img src="../assets/img/moscow-icon.svg" alt="" />
              <span>
                <b>Москва</b>
                Главный офис
              </span>
            </a>
            <a :class="{ active: currentCity == 2 }" @click="currentCity = 2">
              <img src="../assets/img/spb-icon.svg" alt="" />
              <span>
                <b>Санкт-Петербург</b>
                Филиал
              </span>
            </a>
            <a :class="{ active: currentCity == 3 }" @click="currentCity = 3">
              <img src="../assets/img/nn-icon.svg" alt="" />
              <span>
                <b>Нижний Новгород</b>
                Завод по изготовлению металлоконструкций
              </span>
            </a>
          </div>
          <div class="contacts-content" v-show="currentCity == 1">
            <div class="contacts-content__left">
              <div class="contacts-content__left-top">
                <p class="contacts-subtitle">Контактные данные</p>
                <div class="contacts-content__left-item">
                  <p>Call-центр</p>
                  <a href="tel:84996474777">+7 499 647 47 77</a>
                </div>
                <div
                  class="
                    contacts-content__left-item contacts-content__left-email
                  "
                >
                  <p>E-mail</p>
                  <a href="mailto:e-tender@evraz.com">e-tender@evraz.com</a>
                </div>
              </div>
              <div class="contacts-content__left-top">
                <p class="contacts-subtitle">Режим работы</p>
                <div
                  class="
                    contacts-content__left-item contacts-content__left-time
                  "
                >
                  <p>В рабочие дни</p>
                  <div>9:30 - 18:00</div>
                </div>
              </div>
            </div>
            <div class="contacts-content__right">
              <h2 class="contacts-city__title">Москва</h2>
              <p class="contacts-city__address">Летниковская улица, 10с4</p>
              <div class="contacts-content__map">
                <iframe
                  src="https://yandex.com/map-widget/v1/?um=constructor%3A266208a998b076cc031df1dcad6e24efc9111a23c4e28193ba1ef0d6a9faad75&amp;source=constructor"
                  width="100%"
                  height="530"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
          </div>

          <div class="contacts-content" v-show="currentCity == 2">
            <div class="contacts-content__left">
              <div class="contacts-content__left-top">
                <p class="contacts-subtitle">Контактные данные</p>
                <div class="contacts-content__left-item">
                  <p>Call-центр</p>
                  <a href="tel:84996474777">+7 499 647 47 77</a>
                </div>
                <div
                  class="
                    contacts-content__left-item contacts-content__left-email
                  "
                >
                  <p>E-mail</p>
                  <a href="mailto:e-tender@evraz.com">e-tender@evraz.com</a>
                </div>
              </div>
              <div class="contacts-content__left-top">
                <p class="contacts-subtitle">Режим работы</p>
                <div
                  class="
                    contacts-content__left-item contacts-content__left-time
                  "
                >
                  <p>В рабочие дни</p>
                  <div>9:30 - 18:00</div>
                </div>
              </div>
            </div>
            <div class="contacts-content__right">
              <h2 class="contacts-city__title">Санкт-Петербург</h2>
              <p class="contacts-city__address">проспект Металлистов, д. 7</p>
              <div class="contacts-content__map">
                <iframe
                  src="https://yandex.com/map-widget/v1/?um=constructor%3Ab4e959d985054a744f8ccf207d67900af715436b7acdabaa4e8702b196e9a973&amp;source=constructor"
                  width="100%"
                  height="530"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
          </div>

          <div class="contacts-content" v-show="currentCity == 3">
            <div class="contacts-content__left">
              <div class="contacts-content__left-top">
                <p class="contacts-subtitle">Контактные данные</p>
                <div class="contacts-content__left-item">
                  <p>Call-центр</p>
                  <a href="tel:84996474777">+7 499 647 47 77</a>
                </div>
                <div
                  class="
                    contacts-content__left-item contacts-content__left-email
                  "
                >
                  <p>E-mail</p>
                  <a href="mailto:e-tender@evraz.com">e-tender@evraz.com</a>
                </div>
              </div>
              <div class="contacts-content__left-top">
                <p class="contacts-subtitle">Режим работы</p>
                <div
                  class="
                    contacts-content__left-item contacts-content__left-time
                  "
                >
                  <p>В рабочие дни</p>
                  <div>9:30 - 18:00</div>
                </div>
              </div>
            </div>
            <div class="contacts-content__right">
              <h2 class="contacts-city__title">Нижний Новгород</h2>
              <p class="contacts-city__address">ул. Новикова-Прибоя, 4Г</p>
              <div class="contacts-content__map">
                <iframe
                  src="https://yandex.com/map-widget/v1/?um=constructor%3A66b35b56b2720f878d30696cd4a3b5ca37960e4016c8fe782634a93523a721d7&amp;source=constructor"
                  width="100%"
                  height="530"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-vue />
  </section>
</template>

<script>
import HeaderVue from "@/components/common/Header.vue";
import FooterVue from "@/components/common/Footer.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";

export default {
  components: {
    HeaderVue,
    FooterVue,
    BreadCrumbs,
  },
  name: "Contacts",
  data: () => ({
    currentCity: 1,
  }),
  computed: {
    breadcrumbItems() {
      return [
        {
          title: "Контакты",
          link: null,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.contacts-container {
  box-shadow: 5px 10px 50px 0px rgba(175, 175, 175, 0.25);
}
.contacts-nav {
  display: flex;
}
.contacts-nav a {
  display: flex;
  align-items: center;
  padding: 5px 20px 5px 20px;
  margin-right: 20px;
  height: 70px;
  border: 1px solid #a2165b;
  border-radius: 10px;
  transition: all 0.3s;
}

.contacts-nav img {
  margin-right: 20px;
}

.contacts-nav a:hover,
.contacts-nav a.active {
  background: linear-gradient(94.43deg, #a2165b 0%, #5a004b 100%);
  color: white !important;
}

.contacts-nav a:hover img,
.contacts-nav a.active img {
  filter: brightness(0) invert(1);
}
.contacts-nav a b {
  font-size: 24px;
  font-weight: normal;
  display: block;
  line-height: 1;
}

.contacts-nav a span {
  font-size: 12px;
}

.contacts-content {
  display: flex;
  width: 100%;
  margin-top: 40px;
}

.contacts-content__left {
  width: 300px;
}

.contacts-content__right {
  width: calc(100% - 300px);
  padding-left: 60px;
}

.contacts-content__left-top {
  border: 1px solid #e4e4e4;
  padding: 30px;
  + .contacts-content__left-top {
    margin-top: 30px;
  }
  .contacts-subtitle {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .contacts-content__left-item {
    > p {
      color: #6f6f6f;
    }
    + .contacts-content__left-item {
      margin-top: 20px;
    }
  }

  .contacts-content__left-time > div {
    padding-left: 20px;
    background: url(../assets/svg/clock-icon.svg) no-repeat left center;
  }
}

.contacts-city__title {
  font-size: 32px;
  text-transform: uppercase;
}

.contacts-city__address {
  font-size: 18px;
  color: #6f6f6f;
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .contacts-content {
    flex-wrap: wrap;
  }
  .contacts-content__right {
    order: 0;
    width: 100%;
    padding-left: 0;
    margin-bottom: 30px;
  }

  .contacts-content__left {
    order: 1;
    width: 100%;
    display: flex;
  }

  .contacts-content__left-top {
    width: calc(50% - 15px);
    + .contacts-content__left-top {
      margin-left: 30px;
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .contacts-container {
    padding: 20px !important;
  }
  .contacts-city__title {
    font-size: 28px;
  }
  .contacts-nav {
    flex-wrap: wrap;
    a {
      padding: 5px 15px 5px 15px;
      height: auto;
      margin-bottom: 10px;
      img {
        margin-right: 10px;
        max-height: 46px;
      }
      b {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .contacts-content__map iframe {
    height: 360px;
  }

  .contacts-content__left {
    flex-wrap: wrap;
  }
  .contacts-content__left-top {
    width: 100%;
    + .contacts-content__left-top {
      margin-left: 0;
      margin-top: 30px;
    }
  }
}
</style>