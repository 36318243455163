<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 7.5L5.5 5L3 2.5L3.5 1.5L7 5L3.5 8.5L3 7.5Z" fill="#323232" />
  </svg>
</template>

<script>
export default {
  name: "DashIcon",
};
</script>